@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik"), url(./assets/fonts/Rubik-Regular.ttf) format("truetype");
}

html,
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  overflow: hidden;
}
