body {
  overflow: hidden;
}

.MuiButton-startIcon {
  margin-inline-start: -4px !important;
  margin-inline-end: 8px !important;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

.ck.ck-editor {
  width: 100% !important;
}

.ck .ck-balloon-rotator {
  z-index: 9999 !important;
}
.ck-balloon-panel {
  z-index: 9998 !important;
}

:root {
  --ck-z-panel: 9999 !important;
}
